import { GET } from "../../api/api";

async function notification(param) {
  try {
    const res = await GET(`merchant/${param}`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function flaged(param) {
  try {
    const res = await GET(`merchant/set_flaging/${param}`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { notification, flaged };
