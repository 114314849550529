import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
} from "react-bootstrap";
import QrScanner from "react-qr-scanner";
import FormSelect from "../../widget/form-select/FormSelect";
import { formatCardInput, formatNumberWithout } from "../../helper";
import {
  checkBalance,
  activationCard,
  topUpCard,
  checkTransaction,
  validateCard,
} from "./fetch";
import QRCode from "react-qr-code";
import secureLocalStorage from "react-secure-storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
  faIdCard,
  faPlus,
  faQrcode,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import card_ty4 from "../../assets/card.jpg";
import { paymentMethod } from "../PayScanner/fetch";

const Card = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [cardType, setCardType] = useState({});
  const [selectedPayOption, setSelectedPayOption] = useState("");
  const [result, setResult] = useState(null);
  const [disPayNow, setDisPayNow] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [listPayment, setListPayment] = useState([]);
  const [showBalance, setShowBalance] = useState(false);
  const [cameraPos, gantiCemara] = useState("environment");
  const [statusCard, setStatusCard] = useState(0);
  const [cardNumber, setCardNumber] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [intervalQris, setIntervalQris] = useState(null);
  const [qrisValue, setQrisValue] = useState("");
  const [totalBalance, setTotalBalance] = useState(0);
  const [nominalTopUps, setNominalTopUp] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleScanQr = async (data) => {
    setIsCameraActive(false);
    try {
      const res = await validateCard({ unique_code: data.text ?? cardNumber });
      if (res.status) {
        setIsCameraActive(true);
        setResult(data.text ?? cardNumber);
        setStatusCard(res.data.status);
        setCardType(res.data.card_type);
      }
    } catch (error) {
      setIsCameraActive(true);
    }
    if (data) {
      setIsCameraActive(true);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(err.message);
  };

  const handlePaymentMethod = async () => {
    try {
      const res = await paymentMethod({ type: "activation" });
      if (res.status) {
        setListPayment(res.data);
        setSelectedPayOption(res.data[0]);
      } else {
        alert("Gagal Mendapatkan Metode Pembayaran");
      }
    } catch (error) {
      console.error(error);
      alert("Gagal Mendapatkan Metode Pembayaran");
    }
  };

  const handleSelectOption = async (select) => {
    setSelectedOption(select);
    setQrisValue("");
    setNominalTopUp(0);
    if (select === "top_up_card") {
      setShowModal(true);
    } else if (select === "rescan") {
      setCardNumber(null);
      setIsCameraActive(true);
      setShowBalance(false);
      setTotalBalance(0);
      setResult(null);
      setSuccess("");
    } else {
      setShowModal(true);
    }
  };
  const getBalance = async () => {
    if (showBalance === false) {
      try {
        const res = await checkBalance({ unique_code: result });
        if (res.status) {
          setTotalBalance(res.data.amount);
          setShowBalance(true);
        }
      } catch (error) {}
    } else {
      setShowBalance(false);
    }
  };

  const handlePaymentOption = async () => {
    setDisPayNow(true);
    const params = {
      unique_code: result,
      payment_method: selectedPayOption.type,
      merchant_id: secureLocalStorage.getItem("merchant_id"),
      amount: nominalTopUps ?? 0,
    };

    if (selectedOption === "top_up_card") {
      try {
        const res = await topUpCard(params);
        if (res.status) {
          if (selectedPayOption.type === "qris") {
            console.log("123123");
            setQrisValue(res.data.qr_string);
            console.log(res.data.qr_string);
            const newInterval = setInterval(async () => {
              const resTrx = await checkTransaction({
                transaction_id: res.data.transaction_id,
              });
              if (resTrx.status) {
                if (resTrx.data.status === "completed") {
                  clearInterval(newInterval);
                  setShowModal(false);
                  setShowBalance(false);
                  setQrisValue("");
                  setSuccess(res.message);
                  setDisPayNow(false);
                } else {
                  console.log(resTrx.data.status);
                }
              } else {
                setDisPayNow(false);
                clearInterval(newInterval);
              }
            }, 5000);

            setIntervalQris(newInterval);
          } else {
            setDisPayNow(false);
            clearInterval(intervalQris);
            setShowModal(false);
            setShowBalance(false);
            setQrisValue("");
            setSuccess(res.message);
          }
        } else {
          setDisPayNow(false);
        }
      } catch (error) {}
    } else {
      try {
        const res = await activationCard(params);
        if (res.status) {
          if (selectedPayOption.type === "qris") {
            setQrisValue(res.data.qr_string);
            const newInterval = setInterval(async () => {
              const resTrx = await checkTransaction({
                transaction_id: res.data.transaction_id,
              });
              if (resTrx.status) {
                if (resTrx.data.status === "completed") {
                  clearInterval(newInterval);
                  setShowModal(false);
                  setShowBalance(false);
                  setStatusCard(1);
                  setQrisValue("");
                  setSuccess(res.message);
                  setDisPayNow(false);
                } else {
                  console.log(resTrx.data.status);
                }
              } else {
                setDisPayNow(false);
                clearInterval(newInterval);
              }
            }, 5000);

            setIntervalQris(newInterval);
          } else {
            setDisPayNow(false);
            clearInterval(intervalQris);
            setShowModal(false);
            setShowBalance(false);
            setStatusCard(1);
            setQrisValue("");
            setSuccess(res.message);
          }
        }
      } catch (error) {
        setDisPayNow(false);
      }
    }
  };

  useEffect(() => {
    handlePaymentMethod();
  }, []);

  const nominalTopUp = [
    { value: 25000, label: `Rp ${formatNumberWithout(25000)}` },
    { value: 50000, label: `Rp ${formatNumberWithout(50000)}` },
    { value: 100000, label: `Rp ${formatNumberWithout(100000)}` },
    { value: 150000, label: `Rp ${formatNumberWithout(150000)}` },
    { value: 200000, label: `Rp ${formatNumberWithout(200000)}` },
    { value: 300000, label: `Rp ${formatNumberWithout(300000)}` },
    { value: 500000, label: `Rp ${formatNumberWithout(500000)}` },
  ];

  return (
    <Container className="cards" fluid>
      {secureLocalStorage.getItem("type") === "activation" ? (
        <>
          <Modal
            show={showModal}
            onHide={() => [
              setShowModal(false),
              setShowBalance(false),
              clearInterval(intervalQris),
              setDisPayNow(false),
            ]}
            fullscreen
          >
            <Modal.Header closeButton>
              {selectedOption === "top_up_card" ? (
                <h2>Tambah Saldo Kartu</h2>
              ) : (
                <h2>Aktivasi Kartu</h2>
              )}
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Label>Pilih Nominal Saldo</Form.Label>
                {statusCard === 0 ? (
                  <Form.Control
                    className="mb-3"
                    id="type_target"
                    placeholder="Nominal Saldo"
                    as={FormSelect}
                    options={nominalTopUp.filter(
                      (installment) => installment.value >= cardType.amount
                    )}
                    onChange={(e) => setNominalTopUp(e.target.value)}
                  />
                ) : (
                  <Form.Control
                    className="mb-3"
                    placeholder="Nominal Saldo"
                    id="type_target"
                    as={FormSelect}
                    options={nominalTopUp}
                    onChange={(e) => setNominalTopUp(e.target.value)}
                  />
                )}
              </Form>
              {selectedPayOption.type === "qris" && qrisValue !== "" && (
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={qrisValue}
                  viewBox={`0 0 256 256`}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup
                style={{
                  height: "3rem",
                  marginBottom: ".7rem",
                  width: "100%",
                }}
              >
                {listPayment
                  ? listPayment.map((item, index) => {
                      return (
                        <Button
                          className="w-100"
                          key={index}
                          onClick={() => setSelectedPayOption(item)}
                          style={
                            selectedPayOption.type === item.type
                              ? {
                                  backgroundColor: "#24b0a2",
                                  border: "1px solid #24b0a2",
                                  color: "white",
                                }
                              : {
                                  backgroundColor: "white",
                                  border: "1px solid #24b0a2",
                                  color: "#24b0a2",
                                }
                          }
                        >
                          {item.name}
                        </Button>
                      );
                    })
                  : ""}
              </ButtonGroup>
              <Col>
                <Col className="d-flex justify-content-between">
                  <h6
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Subtotal
                  </h6>
                  <h6
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Rp{formatNumberWithout(nominalTopUps ?? 0)}
                  </h6>
                </Col>
                <Col className="d-flex justify-content-between">
                  <h6
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Admin Fee
                  </h6>
                  <h6
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Rp
                    {formatNumberWithout(selectedPayOption.admin_fee ?? 0)}
                  </h6>
                </Col>
                <hr className="dashed-hr" />
                <Col className="d-flex justify-content-between">
                  <h5>Total Pembayaran</h5>
                  <h6
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: 500,
                      marginBottom: "15px",
                    }}
                  >
                    Rp
                    {formatNumberWithout(
                      Number(nominalTopUps) + selectedPayOption.admin_fee ?? 0
                    )}
                  </h6>
                </Col>
              </Col>
              <br />
              <br />
              {nominalTopUps !== 0 && (
                <Button
                  style={{
                    display: "flex",
                    bottom: 0,
                    backgroundColor: "#24b0a2",
                    border: "1px solid #24b0a2",
                  }}
                  className="mb-3 w-100"
                  onClick={() => handlePaymentOption()}
                  disabled={disPayNow}
                >
                  {selectedPayOption.type === "qris"
                    ? "Buat Qris Pembayaran"
                    : "Bayar Sekarang"}
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <Col className="">
            {result !== null ? (
              <>
                <h5 className="text-left mb-3 text-black">Pengaturan Kartu</h5>
                <Col
                  className="col-card py-3"
                  style={{
                    backgroundImage: `url(${card_ty4})`,
                  }}
                >
                  <Col className="d-flex flex-column justify-content-between">
                    <div className="align-self-end px-3">
                      <Button
                        className="btn-add-balance"
                        disabled={statusCard === 1 ? false : true}
                        onClick={() => handleSelectOption("top_up_card")}
                      >
                        Tambah Saldo <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                    <div
                      className="align-self-start px-3"
                      style={{ marginTop: "4rem" }}
                    >
                      <h5
                        className="text-white text-end"
                        style={{ fontWeight: "400" }}
                      >
                        {result}
                      </h5>
                    </div>
                    <div
                      className="align-self-start px-3"
                      style={{ marginTop: ".5rem" }}
                    >
                      <h2
                        className="text-white"
                        style={{ fontWeight: "800" }}
                        onClick={() => getBalance()}
                      >
                        Rp{" "}
                        <FontAwesomeIcon
                          icon={!showBalance ? faEyeSlash : faEye}
                          size="xs"
                        />{" "}
                        <br />{" "}
                        {!showBalance
                          ? "***"
                          : formatNumberWithout(totalBalance)}
                      </h2>
                    </div>
                  </Col>
                </Col>
                <br />
                <Button
                  className="w-100 btn-rescan mb-3"
                  style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.3)" }}
                  onClick={() => handleSelectOption("rescan")}
                >
                  <FontAwesomeIcon
                    icon={faQrcode}
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Scan Ulang
                </Button>
                {statusCard === 0 && (
                  <Button
                    className="w-100 btn-rescan"
                    style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.3)" }}
                    onClick={() => handleSelectOption("activation_card")}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ marginRight: "2px" }}
                    />{" "}
                    Aktivasi Kartu
                  </Button>
                )}
                <br />

                {success && (
                  <Alert variant="success" className="mt-3">
                    <h2>{success}</h2>
                  </Alert>
                )}
              </>
            ) : (
              <>
                <h5 className="text-center mb-3 text-black">Scan Kartu</h5>
                {isCameraActive ? (
                  <>
                    <QrScanner
                      style={{ width: "100%" }}
                      delay={300}
                      onError={handleError}
                      onScan={handleScanQr}
                      className="qr-scanner"
                      constraints={{
                        video: {
                          facingMode: cameraPos,
                        },
                      }}
                    />

                    <Button
                      className="w-100 mb-2"
                      style={{
                        backgroundColor: "#24b0a2",
                        border: "1px solid #24b0a2",
                      }}
                      onClick={() =>
                        gantiCemara(
                          cameraPos === "environment" ? "user" : "environment"
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faRotate}
                        style={{ marginRight: "10px" }}
                      />
                      Ganti Posisi Kamera
                    </Button>
                  </>
                ) : (
                  <>
                    <Form.Control
                      placeholder="Masukkan Nomor Kartu"
                      className="px-4 mb-2 text-center"
                      onChange={(e) =>
                        setCardNumber(formatCardInput(e.target.value))
                      }
                      value={cardNumber}
                    />
                    <Button
                      className="mb-2 w-100"
                      style={{ backgroundColor: "#24b0a2", border: "none" }}
                      onClick={handleScanQr}
                    >
                      Gunakan Kartu
                    </Button>
                  </>
                )}
                <div className="text-center w-100 flex-column">
                  <Button
                    className="mb-4 w-100"
                    style={{ backgroundColor: "#24b0a2", border: "none" }}
                    onClick={() => setIsCameraActive(!isCameraActive)}
                  >
                    {isCameraActive ? (
                      <>
                        <FontAwesomeIcon
                          icon={faIdCard}
                          style={{ marginRight: "10px" }}
                        />
                        Masukan Nomor Kartu
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faQrcode}
                          style={{ marginRight: "10px" }}
                        />
                        Scan Kartu
                      </>
                    )}
                  </Button>
                </div>
              </>
            )}
            {error && (
              <Alert variant="danger" className="mt-3">
                Error: {error}
              </Alert>
            )}
          </Col>
        </>
      ) : (
        <Alert variant="warning" className="mt-3">
          <b>Akses Tidak Tersedia</b>
        </Alert>
      )}
    </Container>
  );
};

export default Card;
