import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Button, Form } from "react-bootstrap";
import { login } from "./fetch";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";

function Login() {
  const [showAlrt, setShowAlert] = useState(false);
  const [showAlrtMess, setShowAlertMess] = useState("");

  const handleClick = async () => {
    const param = { code: document.getElementById("code").value };

    try {
      const res = await login(param);
      if (res.status) {
        secureLocalStorage.setItem("code", res.data.code);
        secureLocalStorage.setItem("merchant_id", res.data.id);
        secureLocalStorage.setItem("type", res.data.type);
        window.location.reload();
      } else {
        setShowAlert(true);
        setShowAlertMess(res.message);
      }
    } catch (error) {
      setShowAlert(true);
      setShowAlertMess(error.message);
    }
  };

  return (
    <Container fluid className="login">
      <Row>
        <Col md={8} className="text-center"></Col>
        <Col md={4} className="" id="container2">
          <Col className="text-center"></Col>
          <h4>Selamat Datang Di PocketsId Merchant</h4>
          <h6>aplikasi merchant by PocketsId</h6>
          <Form>
            <Form.Label className="col-sm-12 text-dark fw-bold">
              Kode Merchant
            </Form.Label>
            <Form.Control
              type="text"
              maxLength={20}
              style={{ textTransform: "uppercase" }}
              id="code"
              className="mb-3"
            />
          </Form>
          <Alert variant="danger" className="mt-3" show={showAlrt}>
            <h4 style={{ color: "red", fontSize: 14 }}>{showAlrtMess}</h4>
          </Alert>
          <Button onClick={() => handleClick()}>Masuk</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
