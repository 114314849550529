import { POST } from "../../api/api";

async function checkBalance(param) {
  try {
    const res = await POST("card/check_balance", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function activationCard(param) {
  try {
    const res = await POST("card/activation_card", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function topUpCard(param) {
  try {
    const res = await POST("card/top_up_card", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function checkTransaction(param) {
  try {
    const res = await POST("card/check_transaction", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function validateCard(param) {
  try {
    const res = await POST("card/validate_card", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export {
  checkBalance,
  topUpCard,
  activationCard,
  checkTransaction,
  validateCard,
};
