import { POST } from "../../api/api";

async function home(param) {
  try {
    const res = await POST("merchant", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { home };
