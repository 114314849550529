import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/Home/home";
import Navigation from "./component/Navigation";
import Login from "./page/Auth/login";
import Notification from "./page/Notification/notification";
import PayScanner from "./page/PayScanner/payscanner";
import secureLocalStorage from "react-secure-storage";
import NotFound from "./page/NotFound/notfound";
import Cards from "./page/Cards/cards";
import { BalanceProvider } from "./page/BalanceContext";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            secureLocalStorage.getItem("code") ? (
              <BalanceProvider>
                <Navigation /> <Home />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/notification"
          element={
            secureLocalStorage.getItem("code") ? (
              <BalanceProvider>
                <Navigation /> <Notification />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/payscanner"
          element={
            secureLocalStorage.getItem("code") ? (
              <>
                <PayScanner />
              </>
            ) : (
              <Login />
            )
          }
        />

        <Route
          path="/cards"
          element={
            secureLocalStorage.getItem("code") ? (
              <BalanceProvider>
                <Navigation /> <Cards />
              </BalanceProvider>
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
