import React, { useContext } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faHistory,
  faContactCard,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { logout } from "../page/Auth/fetch";
import { formatNumberWithout } from "../helper";
import { BalanceContext } from "../page/BalanceContext";

const tabs = [
  {
    route: "/",
    icon: faHome,
    label: "Home",
  },
  {
    route: "/notification",
    icon: faHistory,
    label: "History",
  },
  {
    route: "/cards",
    icon: faContactCard,
    label: "Card ",
  },
];

const handleLogout = async () => {
  const res = await logout(secureLocalStorage.getItem("merchant_id"));
  if (res.status) {
    secureLocalStorage.removeItem("id");
    secureLocalStorage.removeItem("code");
    secureLocalStorage.removeItem("merchant_id");
    secureLocalStorage.removeItem("type");
    window.location.reload();
  } else {
    alert("Gagal Logout");
  }
};

const Navigation = (props) => {
  const { balance } = useContext(BalanceContext);
  const location = useLocation();

  return (
    <div>
      <nav
        className="navbar navbar-expand-md navbar-light fixed-top"
        role="navigation"
      >
        <div className="container px-1">
          {location.pathname === "/" ? (
            <Col>
              <Nav className="w-100 d-flex justify-content-between mb-3">
                <NavItem className="">
                  <NavLink
                    to="/"
                    className="nav-link"
                    style={{ fontWeight: 800, textTransform: "capitalize" }}
                  >
                    PocketsId {secureLocalStorage.getItem("type")}
                  </NavLink>
                </NavItem>

                <Row>
                  <Col>
                    <NavItem>
                      <NavLink className="nav-link" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} /> Keluar
                      </NavLink>
                    </NavItem>
                  </Col>
                </Row>
              </Nav>
              <Col className="col-top">
                <h5
                  className="text-black ps-3 pb-2"
                  style={{ fontWeight: 500 }}
                >
                  Hallo, Merchant #{secureLocalStorage.getItem("merchant_id")}
                </h5>
                <Row className="col-sec-1">
                  <Col className="col-ballance" style={{ marginRight: "5px" }}>
                    <h6
                      className="text-left"
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#aeaeae",
                      }}
                    >
                      Saldo Merchant
                    </h6>
                    <span className="ballance">
                      {formatNumberWithout(balance.balance ?? 0)}
                    </span>
                  </Col>
                  <Col className="col-ballance" style={{ marginLeft: "5px" }}>
                    <h6
                      className="text-left"
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#aeaeae",
                      }}
                    >
                      Saldo Cash
                    </h6>
                    <span className="ballance">
                      {formatNumberWithout(balance.cash ?? 0)}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Col>
          ) : (
            <Nav className="w-100 d-flex justify-content-between">
              <NavItem className="">
                <NavLink
                  to="/"
                  className="nav-link"
                  style={{ fontWeight: 800, textTransform: "capitalize" }}
                >
                  PocketsId {secureLocalStorage.getItem("type")} #
                  {secureLocalStorage.getItem("merchant_id")}
                </NavLink>
              </NavItem>

              <Row>
                <Col>
                  <NavItem>
                    <NavLink className="nav-link" onClick={handleLogout}>
                      <FontAwesomeIcon icon={faArrowAltCircleRight} /> Keluar
                    </NavLink>
                  </NavItem>
                </Col>
              </Row>
            </Nav>
          )}
        </div>
      </nav>
      {/* Bottom Tab Navigator*/}
      <nav className="navbar fixed-bottom navbar-light" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink to={tab.route} className="nav-link">
                  <div className="text-center">
                    <FontAwesomeIcon size="xs" icon={tab.icon} />
                    <h6>{tab.label}</h6>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default Navigation;
