import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { flaged, notification } from "./fetch";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import {
  hourformat,
  formatNumberWithout,
  formatDate,
  groupByDate,
} from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

const Notification = (props) => {
  const [notificationData, setNotificationData] = useState([]);

  const getNotification = async () => {
    try {
      const res = await notification(secureLocalStorage.getItem("merchant_id"));
      if (res.status) {
        setNotificationData(groupByDate(res.data));
      } else {
        setNotificationData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFlaged = async (id) => {
    try {
      const res = await flaged(id);
      if (res.status) {
        alert("Berhasil Memberi Tanda");
        getNotification();
      } else {
        alert("Gagal Memberi Tanda");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkTransaction = (id) => {
    const transactions = {
      1: "Permbayaran Merchant",
      2: "product",
      3: "withdraw",
      4: "Penambahan Saldo",
      5: "Pengurangan Saldo",
      6: "Topup Kartu",
    };

    return transactions[id] || "Transaction not found";
  };

  const checkPaymentMethod = (id) => {
    const paymentMethod = {
      cash: "Tunai",
      "scan-qr": "PocketsId Card",
      qris: "QRIS",
      card: "PocketsId Card",
    };

    return paymentMethod[id] || "Transaction not found";
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <Col className="notification">
      <Col>
        <Tabs fill variant="pill">
          <Tab eventKey="notification" title={"History Penjualan"}>
            <Col className="mt-4 p-0">
              {notificationData
                ? notificationData.map((items, index) => {
                    return (
                      <Col key={index}>
                        <h6
                          className="py-1 text-center mx-3"
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "rgba(247, 247, 247, 1)",
                            color: "rgba(76, 77, 80, 1)",
                            fontWeight: 400,
                            paddingInline: "40px",
                          }}
                        >
                          <b>{formatDate(items.created_at)}</b>
                        </h6>
                        {items.transactions
                          ? items.transactions.map((item, index) => {
                              const isLastItem =
                                index === items.transactions.length - 1;
                              return (
                                <Col
                                  className="px-3 mt-3"
                                  style={{ backgroundColor: "white" }}
                                  key={index}
                                >
                                  <Row>
                                    <Col className="text-start" xs={8}>
                                      <h6 className="mb-3">
                                        <b>#{item.transaction_id}</b> -{" "}
                                        {checkPaymentMethod(
                                          item.payment_method
                                        )}{" "}
                                        {item.last_digit_card &&
                                          `- *${item.last_digit_card}`}
                                      </h6>
                                    </Col>
                                    <Col className="text-end" xs={4}>
                                      {hourformat(item.created_at)}
                                      {item.flaged === null && (
                                        <FontAwesomeIcon
                                          style={{
                                            marginLeft: "10px",
                                            color: "#24b0a2",
                                          }}
                                          icon={faFlag}
                                          onClick={() =>
                                            handleFlaged(item.transaction_id)
                                          }
                                        />
                                      )}
                                    </Col>
                                  </Row>

                                  <Row className="mb-1">
                                    <Col className="text-start" xs={8}>
                                      <h6 style={{ fontWeight: 500 }}>
                                        {checkTransaction(
                                          item.transaction_type
                                        )}
                                      </h6>
                                    </Col>
                                    <Col
                                      className="text-end text-success"
                                      xs={4}
                                    >
                                      + Rp{" "}
                                      {formatNumberWithout(item.grand_total)}
                                    </Col>
                                  </Row>
                                  {!isLastItem && <hr />}
                                </Col>
                              );
                            })
                          : ""}
                      </Col>
                    );
                  })
                : ""}
            </Col>
          </Tab>
        </Tabs>
      </Col>
    </Col>
  );
};

export default Notification;
