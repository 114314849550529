const formatNumberWithout = (number) => {
  try {
    const formattedNumber = number.toLocaleString();
    const [integerPart, decimalPart] = formattedNumber.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedCurrency = decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
    return formattedCurrency;
  } catch (e) {
    console.error(e);
  }
};

function formatCurrencyInput(input) {
  const numericInput = input.replace(/[^0-9.]/g, "");
  const [integerPart, decimalPart] = numericInput.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedCurrency = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
  return formattedCurrency;
}

const groupByCategoryName = (variations) => {
  const grouped = variations.reduce((acc, variation) => {
    const { category_name } = variation;
    if (!acc[category_name]) {
      acc[category_name] = [];
    }
    acc[category_name].push(variation);
    return acc;
  }, {});

  // Convert the object into an array of objects
  return Object.keys(grouped).map((categoryName) => ({
    category_name: categoryName,
    variations: grouped[categoryName],
  }));
};

const groupByDate = (transactions) => {
  // Reduce the transactions into an object grouped by the date
  const grouped = transactions.reduce((acc, transaction) => {
    const date = transaction.created_at.split(" ")[0]; // Extract the date part (YYYY-MM-DD)
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(transaction);
    return acc;
  }, {});

  // Convert the object into an array of objects
  return Object.keys(grouped).map((date) => ({
    created_at: date,
    transactions: grouped[date],
  }));
};

const hourformat = (tanggalISO) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const tanggal = new Date(tanggalISO);
  return tanggal.toLocaleString("id-ID", options);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("id-ID", options).format(date);
  return formattedDate;
};

const formatCardInput = (value) => {
  // Hapus semua karakter selain angka
  let sanitizedValue = value.replace(/\D/g, "");

  // Batasi panjang maksimal 16 karakter
  if (sanitizedValue.length > 16) {
    sanitizedValue = sanitizedValue.substring(0, 16);
  }

  // Tambahkan tanda "-" setiap 4 karakter
  const formattedValue = sanitizedValue.match(/.{1,4}/g)?.join("-") || "";

  return formattedValue;
};

export {
  formatNumberWithout,
  formatCurrencyInput,
  groupByCategoryName,
  hourformat,
  groupByDate,
  formatDate,
  formatCardInput,
};
