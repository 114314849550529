import { POST } from "../../api/api";

async function transaction(param) {
  try {
    const res = await POST("transaction/create", param);
    return res;
  } catch (error) {
    return null;
  }
}

async function checkOtp(param) {
  try {
    const res = await POST("transaction/checkOtp", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function paymentMethod(param) {
  try {
    const res = await POST("merchant/payment_method", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { transaction, checkOtp, paymentMethod };
